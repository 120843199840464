import React, { useState } from 'react';
import {PlusCircleIcon} from "@heroicons/react/24/outline";

const CreateList = ({ onCreate, project_id }) => {
    const [hasChanged, setHasChanged] = useState(false);
    const [formData, setFormData] = useState({
        name: { value: '', isValid: false },
    })

    const handleSubmit = (e) => {
        e.preventDefault();

        // Assuming you have a function to call the API to create a new list
        onCreate({ name: formData.name.value, project_id });

        // Reset the form fields
        formData.name.value = '';
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        let isValid = false;

        if (name === 'name') {
            isValid = value.length > 0 && value.length <= 68;

            // Force lowercase
            value = value.toLowerCase();
        }

        setHasChanged(true)

        setFormData({
            ...formData,
            [name]: { value, isValid },
        });
    }

    const isFormValid = () => {
        return Object.values(formData).every(field => field.isValid);
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <form>
            <div className="max-w-64">
                <label htmlFor="name" className="block text-sm font-medium leading-6 text-stone-900">
                    Name
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name.value}
                        onChange={handleChange}
                        className={classNames(
                            formData.name.isValid ? 'ring-stone-300' : 'ring-red-300',
                            'block w-full min-w-0 flex-1 bg-stone-50 rounded-md rounded-r-md border-0 py-1.5 text-stone-900 ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-1 focus:ring-inset focus:ring-stone-300 sm:text-sm sm:leading-6'
                        )}
                    />
                </div>
            </div>

            <button
                onClick={handleSubmit}
                type="button"
                disabled={!isFormValid()}
                className={classNames(
                    hasChanged && isFormValid() ? 'bg-green-800 hover:bg-stone-600' : 'bg-stone-300',
                    'mt-3 inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-regular text-white'
                )}
            >
                <PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                Create
            </button>
        </form>
    );
};

export default CreateList;