import React, {useEffect, useState} from 'react';
import ListDisplay from "./../../components/ListDisplay";
import { useLocation } from 'react-router-dom';

function List() {
    const [listData, setListData] = useState('');
    const [items, setItems] = useState([]);
    const location = useLocation();
    const pathname = location.pathname;
    const firstPathSegment = pathname.split("/")[1];

    useEffect(() => {
        // Replace the URL with your actual endpoint
        fetch(`/api/v1/lists/${firstPathSegment}`)
            .then(response => response.json())
            .then(data => {
                setListData(data.data)
                setItems(data.data.items)
            })
            .catch(error => console.error("There was an error fetching the list items:", error));
    }, []);

    return (
        <>
            { listData && listData.items.length > 0 ?
                <>
                    <div className="h-full mx-auto" style={{background: listData.theme_colors.background}}>
                        <div className="container mx-auto max-w-xl">
                            <div className="md:py-8 md:flex items-center justify-center">
                                <div
                                    className="md:min-w-96">
                                    <div className="p-2 pt-4 md:p-8 md:pb-0">
                                        <div className="px-4 sm:px-0 text-center">
                                            <img src={`/api/v1/lists/${firstPathSegment}/logo.png`} alt={listData.name} className="h-16 w-auto m-auto"/>
                                            <p className="mt-2 max-w-2xl text-xs leading-6"
                                               style={{color: listData.theme_colors.text}}>{listData.text}</p>
                                        </div>
                                        <div className="mt-6">
                                            <ListDisplay items={items} buttonColor={listData.theme_colors.button}
                                                         buttonTextColor={listData.theme_colors.button_text}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <></>
            }
        </>
    );
}

export default List;
