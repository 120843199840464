import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import List from "./pages/front/List";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/protected/Dashboard";
import ProtectedRoute from "./utils/ProtectedRoute";
import PublicLayout from "./layouts/PublicLayout";
import Project from "./pages/protected/project/Project";
import EditList from "./pages/protected/list/EditList";

function App() {
  return (
      <Router>
          <Routes>
              <Route path="/" element={<PublicLayout><List /></PublicLayout>} />
              <Route path="/login" element={<PublicLayout><Login /></PublicLayout>} />
              <Route path="/dashboard" element={
                  <ProtectedRoute>
                      <Dashboard />
                  </ProtectedRoute>
              } />
              <Route path="/projects/:projectId" element={
                  <ProtectedRoute>
                      <Project />
                  </ProtectedRoute>
              } />
              <Route path="/lists/:listId" element={
                  <ProtectedRoute>
                      <EditList />
                  </ProtectedRoute>
              } />
              <Route path="*" element={<PublicLayout><List /></PublicLayout>} />
          </Routes>
      </Router>
  );
}

export default App;
