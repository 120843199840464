import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {ChevronLeftIcon, PlusCircleIcon} from "@heroicons/react/24/outline";
import AddLink from "../../../components/forms/AddLink";
import EditTheme from "../../../components/forms/EditTheme";
import ImageUpload from "../../../components/forms/ImageUpload";

const EditList = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [themeError, setThemeError] = useState(null);
    const [pendingChange, setPendingChange] = useState(false);
    const { listId } = useParams();

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const handleRemoteItem = (index) => {
        const newItems = list.items.filter((item, i) => i !== index);
        setList({ ...list, items: newItems });
        setPendingChange(true);
    }

    const handleUpdateItems = async (newLink) => {
        try {
            let postData = {items: list.items}
            if (postData.items && postData.items.length > 0) {
                if (newLink && newLink.text) {
                    postData = {
                        ...postData,
                        items: [...postData.items, newLink]
                    };
                }
            } else {
                if (newLink && newLink.text) {
                    postData = {
                        items: [newLink]
                    }
                }
            }

            const token = localStorage.getItem('token'); // Assuming the token is stored in local storage
            const response = await fetch(`/api/v1/lists/${list.id}/items`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the auth token in the Authorization header
                },
                body: JSON.stringify(postData)
            });

            if (!response.ok) {
                throw new Error('Failed to create list');
            }

            // Handle successful list creation (e.g., redirect or show a success message)
            console.log('List items updated successfully');

            const data = await response.json()
            setList(data.data)

            setPendingChange(false)

            console.log(data.data)
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdateTheme = async (newTheme) => {
        try {
            setThemeError(null)

            const token = localStorage.getItem('token'); // Assuming the token is stored in local storage
            const response = await fetch(`/api/v1/lists/${list.id}/theme`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the auth token in the Authorization header
                },
                body: JSON.stringify(newTheme)
            });

            if (!response.ok) {
                if (response.status === 400) {
                    const data = await response.json()
                    setThemeError({message: data.message})

                    if (data.data && data.data.errors) {
                        setThemeError({message: data.message, errors: data.data.errors})
                    }

                    throw new Error('Invalid theme colors');
                }

                throw new Error('Failed to create list');
            }

            // Handle successful list creation (e.g., redirect or show a success message)
            console.log('List theme updated successfully');

            const data = await response.json()
            setList(data.data)
        } catch (error) {
            console.error(error);
        }
    };

    const moveItem = (index, direction) => {
        const newIndex = index + direction;
        if (newIndex < 0 || newIndex >= list.items.length) return;

        // Creating a new items array for immutability
        const newItems = [...list.items];
        // Swap items
        const itemToMove = newItems.splice(index, 1)[0];
        newItems.splice(newIndex, 0, itemToMove);

        // Update the list state with the new items array
        setList({ ...list, items: newItems });

        setPendingChange(true);
    };

    useEffect(() => {
        const fetchListDetails = async () => {
            setIsLoading(true);
            try {
                const token = localStorage.getItem('token'); // Assuming the token is stored in local storage
                const response = await fetch(`/api/v1/lists/${listId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Include the auth token in the Authorization header
                    },
                });

                if (!response.ok) {
                    throw new Error('Could not fetch project details.');
                }

                const data = await response.json();
                setList(data.data);

                console.log(data.data)
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchListDetails();
    }, [listId]);

    if (isLoading) return (
        <div>
            <Link to={`/dashboard`}>
                <div>
                    <ChevronLeftIcon className="h-5 w-5 text-stone-600 -ml-1 inline mr-2 -mt-1"
                                     aria-hidden="true"/>
                    <p className="inline max-w-2xl text-sm leading-6 text-stone-500"></p>
                </div>
            </Link>
            <div className="mt-2">
                <div className="py-4">
                    <h3 className="text-base font-semibold leading-7 text-stone-600 md:-mt-2"></h3>
                    <p className="mt-1 max-w-2xl text-xs leading-6 text-stone-500">Here you can view and create new
                        links. These lists will be shown on your list called.</p>
                </div>
            </div>

            <div>Loading...</div>
        </div>
    );

    if (error) return (
        <div>
            <Link to={`/dashboard`}>
                <div>
                    <ChevronLeftIcon className="h-5 w-5 text-stone-600 -ml-1 inline mr-2 -mt-1"
                                     aria-hidden="true"/>
                    <p className="inline max-w-2xl text-sm leading-6 text-stone-500">Dashboard</p>
                </div>
            </Link>
            <div className="mt-2">
                <div className="py-4">
                    <h3 className="text-base font-semibold leading-7 text-stone-600 md:-mt-2"></h3>
                    <p className="mt-1 max-w-2xl text-xs leading-6 text-stone-500">Here you can view and create new
                        links. These lists will be shown on your list called.</p>
                </div>
            </div>

            <div>Error: {error}</div>
        </div>
    );

    if (!list) return (
        <div>
            <Link to={`/dashboard`}>
                <div>
                    <ChevronLeftIcon className="h-5 w-5 text-stone-600 -ml-1 inline mr-2 -mt-1"
                                     aria-hidden="true"/>
                    <p className="inline max-w-2xl text-sm leading-6 text-stone-500">Dashboard</p>
                </div>
            </Link>
            <div className="mt-2">
                <div className="py-4">
                    <h3 className="text-base font-semibold leading-7 text-stone-600 md:-mt-2"></h3>
                    <p className="mt-1 max-w-2xl text-xs leading-6 text-stone-500">Here you can view and create new
                        links. These lists will be shown on your list called.</p>
                </div>
            </div>


            <div>No list found.</div>
        </div>
    );

    return (
        <div>
            <Link to={`/projects/${list.project_id}`}>
                <div>
                    <ChevronLeftIcon className="h-5 w-5 text-stone-600 -ml-1 inline mr-2 -mt-1"
                                     aria-hidden="true"/>
                    <p className="inline max-w-2xl text-sm leading-6 text-stone-500">Project</p>
                </div>
            </Link>
            <div className="mt-2">
                <div className="py-4">
                    <h3 className="text-base font-semibold leading-7 text-stone-600 md:-mt-2">{list.name}</h3>
                    <p className="mt-1 max-w-2xl text-xs leading-6 text-stone-500">Here you can view and create new
                        links. These lists will be shown on your list called.</p>
                </div>

                {list && list.items ?
                    <>
                        <div className="pb-5">
                            <dl className="">
                                {list.items.map((item, index) => (
                                    <div key={index} className="py-3">
                                        <div className="bg-stone-50 px-3 py-3 pt-2 rounded-lg border border-stone-200">
                                            <div className="text-sm font-normal leading-6 text-stone-600">
                                                {item.text}
                                            </div>
                                            <div>
                                                <p className="mt-1 max-w-2xl text-xs leading-6 text-stone-500">{item.url}</p>
                                            </div>
                                            <div>
                                                <span className="isolate inline-flex rounded-md shadow-sm mt-3">
                                                  <button
                                                      type="button"
                                                      onClick={() => moveItem(index, -1)} disabled={index === 0}
                                                      className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-xs font-semibold text-stone-500 ring-1 ring-inset ring-stone-200 hover:bg-stone-50 focus:z-10"
                                                  >
                                                    Up
                                                  </button>
                                                  <button
                                                      type="button"
                                                      onClick={() => moveItem(index, 1)}
                                                      disabled={index === list.items.length - 1}
                                                      className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-xs font-semibold text-stone-500 ring-1 ring-inset ring-stone-200 hover:bg-stone-50 focus:z-10"
                                                  >
                                                    Down
                                                  </button>
                                                  <button
                                                      type="button"
                                                      onClick={() => handleRemoteItem(index)}
                                                      className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-xs font-semibold text-stone-500 ring-1 ring-inset ring-stone-200 hover:bg-stone-50 focus:z-10"
                                                  >
                                                    Remove
                                                  </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </dl>

                            <button
                                type="button"
                                onClick={handleUpdateItems}
                                disabled={pendingChange !== true}
                                className={classNames(
                                    pendingChange ? 'bg-green-800 hover:bg-stone-600' : 'bg-stone-300',
                                    'mt-3 inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-regular text-white'
                                )}
                            >
                                <PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                Save
                            </button>
                        </div>
                    </>
                    :
                    <></>
                }

                <div className="mb-6 border-t border-stone-200 mt-2 pt-4">
                    <h3 className="max-w-2xl text-sm font-normal leading-6 text-stone-500">Add a new link</h3>

                    <p className="mt-1 pb-3 max-w-2xl text-xs leading-6 text-stone-500">Here you can add a new link.
                        This link will appear on your list page.</p>

                    <AddLink onCreate={handleUpdateItems}/>
                </div>

                <div className="mb-6 border-t border-stone-200 mt-2 pt-4">
                    <h3 className="max-w-2xl text-sm font-normal leading-6 text-stone-500">Add a logo</h3>

                    <p className="mt-1 pb-3 max-w-2xl text-xs leading-6 text-stone-500">Here you can add a new logo.
                        This logo will appear on top your list page.</p>

                    <ImageUpload onCreate={handleUpdateItems} listId={list.id}/>
                </div>

                <div className="mb-6 border-t border-stone-200 mt-2 pt-4">
                    <h3 className="max-w-2xl text-sm font-normal leading-6 text-stone-500">Edit theme colors</h3>

                    <p className="mt-1 pb-3 max-w-2xl text-xs leading-6 text-stone-500">Here you can customize your list
                        page colors using hexadecimal (hex) codes, which are a six-digit combination of numbers and
                        letters that define specific colors.</p>

                    <div>
                        {themeError && themeError.length > 0 ?
                            <>
                                <div className="rounded-md bg-red-50 p-4">
                                    <div className="flex">
                                        <div>
                                            <h3 className="text-sm font-medium text-red-800">Error when updating theme
                                                colors</h3>
                                            <div className="mt-2 text-sm text-red-700">
                                                <ul role="list" className="list-disc space-y-1 pl-5">
                                                    {themeError.errors.map((error, index) => (
                                                        <li key={index}>{error.message}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <></>}
                    </div>

                    <EditTheme themeColors={list.theme_colors} onCreate={handleUpdateTheme}/>
                </div>
            </div>
        </div>
    );
};

export default EditList;