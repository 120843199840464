import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import CreateList from "../../../components/forms/CreateList";

const Project = () => {
    const [project, setProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { projectId } = useParams();

    const handleCreateList = async (listData) => {
        try {
            const token = localStorage.getItem('token'); // Assuming the token is stored in local storage
            const response = await fetch(`/api/v1/lists`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the auth token in the Authorization header
                },
                body: JSON.stringify(listData)
            });

            if (!response.ok) {
                throw new Error('Failed to create list');
            }

            // Handle successful list creation (e.g., redirect or show a success message)
            console.log('List created successfully');

            const data = await response.json()

            // Check if lists is empty
            if (!project.lists) {
                setProject(prevState => {
                    return {
                        ...prevState,
                        lists: [data.data]
                    }
                });
                return;
            }

            // Append new list to the project lists
            setProject(prevState => {
                return {
                    ...prevState,
                    lists: [...prevState.lists, data.data]
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchProjectDetails = async () => {
            setIsLoading(true);
            try {
                const token = localStorage.getItem('token'); // Assuming the token is stored in local storage
                const response = await fetch(`/api/v1/projects/${projectId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Include the auth token in the Authorization header
                    },
                });

                if (!response.ok) {
                    throw new Error('Could not fetch project details.');
                }

                const data = await response.json();
                setProject(data.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProjectDetails();
    }, [projectId]);

    if (isLoading) return (
        <div>
            <div>Loading...</div>
        </div>
    );

    if (error) return (
        <div>
            <div>Error: {error}</div>
        </div>
    );

    if (!project) return (
        <div>
            <div>No project found.</div>
        </div>
    );

    return (
        <div>
            <Link to={`/dashboard`}>
                <div>
                    <ChevronLeftIcon className="h-5 w-5 text-stone-600 -ml-1 inline mr-2 -mt-1"
                                     aria-hidden="true"/>
                    <p className="inline max-w-2xl text-sm leading-6 text-stone-500">Back</p>
                </div>
            </Link>
            <div className="mt-2">
                <div className="py-4">
                    <h3 className="text-base font-semibold leading-7 text-stone-600 md:-mt-2">{project.name}</h3>
                    <p className="mt-1 max-w-2xl text-xs leading-6 text-stone-500">Here you can view and create new lists. These lists will be linked to your project called {project.name}.</p>
                </div>

                {project.lists && project.lists.length > 0 ?
                    <>
                        <div className="pb-5">
                            <dl className="">
                                {project.lists.map(list => (
                                    <div key={list.id} className="py-3">
                                        <Link to={`/lists/${list.name}`}>
                                            <div className="bg-stone-50 px-2 py-3 rounded-lg border border-stone-200">
                                                <div className="text-sm font-normal leading-6 text-stone-600">
                                                    <ChevronRightIcon className="h-4 w-4 text-stone-600 inline mr-2"
                                                                      aria-hidden="true"/>
                                                    {list.name}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </>
                    :
                    <></>
                }

                <div className="mb-6">
                    <h3 className="max-w-2xl text-xs font-normal leading-6 text-stone-500">Create a new list</h3>

                    <div className="border-t border-stone-200 mt-2 mb-3"></div>

                    <CreateList onCreate={handleCreateList} project_id={project.id} />
                </div>
            </div>
        </div>
    );
};

export default Project;