import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import CreateList from "../../components/forms/CreateList";
import CreateProject from "../../components/forms/CreateProject";

export default function Dashboard() {
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleCreateProject = async (projectData) => {
        try {
            const token = localStorage.getItem('token'); // Assuming the token is stored in local storage
            const response = await fetch(`/api/v1/projects`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the auth token in the Authorization header
                },
                body: JSON.stringify(projectData)
            });

            if (!response.ok) {
                throw new Error('Failed to create list');
            }

            // Handle successful list creation (e.g., redirect or show a success message)
            console.log('List created successfully');

            const data = await response.json()

            // Append new project to projects
            setProjects(prevState => {
                return [
                    ...prevState,
                    data.data
                ]
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('/api/v1/projects', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (response.status === 401) {
                    const isAuthenticated = localStorage.getItem('token');

                    // If unauthorized, remove the token and redirect to login or handle accordingly
                    localStorage.removeItem('token');
                    navigate('/login'); // Redirect user to login page
                    return;
                }

                if (response.status === 404) {
                    return;
                }

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`); // Handle non-2xx responses
                }

                const data = await response.json();
                setProjects(data.data); // Assuming the response body is the array of projects
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProjects();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <div className="mb-6">
                <h3 className="max-w-2xl text-sm leading-6 text-stone-500">Projects</h3>

                <dl>
                    {projects.map(project => (
                        <div key={project.id} className="py-3">
                            <Link to={`/projects/${project.id}`}>
                                <div className="bg-stone-50 px-2 py-3 rounded-lg border border-stone-200">
                                    <div className="text-sm font-normal leading-6 text-stone-600">
                                        <ChevronRightIcon className="h-4 w-4 text-stone-600 inline mr-2"
                                                          aria-hidden="true"/>
                                        {project.name}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </dl>

                <div className="mb-6">
                    <h3 className="max-w-2xl text-xs font-normal leading-6 text-stone-500">Create a new list</h3>

                    <div className="border-t border-stone-200 mt-2 mb-3"></div>

                    <CreateProject onCreate={handleCreateProject} />
                </div>
            </div>
        </>
    )
}
