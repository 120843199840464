import React from 'react';

const ProtectedLayout = ({ children }) => (
    <div className="bg-stone-50 mx-auto">
        <div className="h-full container mx-auto max-w-xl">
            <div className="md:py-8 md:flex items-center justify-center">
                <div
                    className="md:bg-white md:rounded-xl md:min-w-96 shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="p-2 pt-4 md:p-8 md:pb-0">
                        {children}
                    </div>

                    <div className="bg-stone-900 md:rounded-b-lg p-2 md:p-8">
                        <div>
                            <p className="text-sm text-stone-400 px-4 md:p-0">Privacy-focused
                                Link Tree! For privacy-conscious individuals who value anonymity and
                                demand a no-external-trace, zero-cookie service.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ProtectedLayout;