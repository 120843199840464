import React, { useState } from 'react';
import {CheckCircleIcon, PhotoIcon, UserCircleIcon} from "@heroicons/react/16/solid";
import {XMarkIcon} from "@heroicons/react/24/outline";

function ImageUpload({onCreate, listId}) {
    const [successfulUpload, setSuccessfulUpload] = useState(false);
    const handleImageChange = (e) => {
        e.preventDefault();

        if (e.target.files && e.target.files[0]) {
            handleSubmit(e.target.files[0])
        }
    };

    const handleSubmit = async (data) => {
        const formData = new FormData();
        formData.append('logo', data);

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`/api/v1/lists/${listId}/logo`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the auth token in the Authorization header
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 400) {
                    throw new Error('Invalid image');
                }

                throw new Error('Failed to upload image');
            }

            setSuccessfulUpload(true);
            onCreate('success')
        } catch (error) {
            console.error('Error:', error);
            alert('Error uploading image');
        }
    };

    return (
        <>
            <form>
                <div className="col-span-full">
                    { successfulUpload ?
                        <>
                            <div className="rounded-md bg-green-50 p-4 px-5">
                                <p className="text-sm font-medium text-green-800">Successfully uploaded</p>
                            </div>
                        </> : <></>
                    }

                    <div
                        className="mt-2 flex justify-center rounded-lg border border-dashed border-stone-900/25 px-6 py-10">
                        <div className="text-center">
                            <PhotoIcon className="mx-auto h-12 w-12 text-stone-300" aria-hidden="true"/>
                            <div className="mt-4 flex text-sm leading-6 text-stone-600">
                                <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-stone-600 focus:outline-none hover:text-stone-500"
                                >
                                    <span>Upload logo</span>
                                    <input id="file-upload" name="file-upload" className="sr-only" type="file"
                                           accept="image/png" onChange={handleImageChange}/>
                                </label>
                            </div>
                            <p className="text-xs leading-5 text-stone-600">PNG up to 10MB</p>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default ImageUpload;
