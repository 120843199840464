import React, { useState } from 'react';
import {PlusCircleIcon} from "@heroicons/react/24/outline";

const AddLink = ({ onCreate }) => {
    const [hasChanged, setHasChanged] = useState(false);
    const [formData, setFormData] = useState({
        text: { value: '', isValid: true },
        url: { value: '', isValid: true },
    })

    const handleSubmit = (e) => {
        e.preventDefault();

        // Assuming you have a function to call the API to create a new list
        onCreate({ text: formData.text.value, url: formData.url.value });

        // Reset the form fields
        formData.text.value = '';
        formData.url.value = '';
    };

    const handleChange = (e) => {
        let { name, value } = e.target;
        let isValid = false;

        if (name === 'text') {
            isValid = value.length > 0 && value.length <= 68;
        }

        if (name === 'url') {
            isValid = isValidUrl(value);
            console.log(isValid)
        }

        setHasChanged(true)

        setFormData({
            ...formData,
            [name]: { value, isValid },
        });
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    function isValidUrl(url) {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(url);
    }

    const isFormValid = () => {
        return Object.values(formData).every(field => field.isValid);
    };

    return (
        <form>
            <div className="max-w-64">
                <label htmlFor="text" className="block text-sm font-medium leading-6 text-stone-900">
                    Text
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="text"
                        id="text"
                        value={formData.text.value}
                        onChange={handleChange}
                        className={classNames(
                            formData.text.isValid ? 'ring-stone-300' : 'ring-red-300',
                            'block w-full min-w-0 flex-1 bg-stone-50 rounded-md rounded-r-md border-0 py-1.5 text-stone-900 ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-1 focus:ring-inset focus:ring-stone-300 sm:text-sm sm:leading-6'
                        )}
                    />
                </div>
            </div>

            <div className="max-w-64 mt-3">
                <label htmlFor="url" className="block text-sm font-medium leading-6 text-stone-900">
                    URL
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="url"
                        id="url"
                        value={formData.url.value}
                        onChange={handleChange}
                        className={classNames(
                            formData.url.isValid ? 'ring-stone-300' : 'ring-red-300',
                            'block w-full min-w-0 flex-1 bg-stone-50 rounded-md rounded-r-md border-0 py-1.5 text-stone-900 ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-1 focus:ring-inset focus:ring-stone-300 sm:text-sm sm:leading-6'
                        )}
                    />
                </div>
            </div>

            <button
                onClick={handleSubmit}
                type="button"
                disabled={!isFormValid()}
                className={classNames(
                    hasChanged && isFormValid() ? 'bg-green-800 hover:bg-stone-600' : 'bg-stone-300',
                    'mt-3 inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-regular text-white'
                )}
            >
                <PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                Create
            </button>
        </form>
    );
};

export default AddLink;