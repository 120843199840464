import React from 'react';

const ListDisplay = ({items, buttonColor, buttonTextColor}) => {
    return (
        <>
            <dl>
                {items.map((item) => (
                    <div key={item.id}
                        className="my-4">
                        <a href={item.url} target="_blank" rel="noreferrer">
                            <div className="text-sm font-medium text-center px-3 py-3 sm:px-0 rounded-xl" style={{ color: buttonTextColor, background: buttonColor }}>
                                {item.text}
                            </div>
                        </a>
                    </div>
                ))}
            </dl>
        </>
    );
};

export default ListDisplay;