import React from 'react';
import { Navigate } from 'react-router-dom';
import ProtectedLayout from './../layouts/ProtectedLayout';

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem('token'); // Simple auth check
    return isAuthenticated && isAuthenticated.length > 0 ? (
        <ProtectedLayout>{children}</ProtectedLayout>
    ) : (
        <Navigate to="/login" replace />
    );
};

export default ProtectedRoute;