import React, { useState } from 'react';
import {PlusCircleIcon} from "@heroicons/react/24/outline";

const EditTheme = ({ themeColors, onCreate }) => {
    const [hasChanged, setHasChanged] = useState(false);
    const [formData, setFormData] = useState({
        background: { value: themeColors.background.substring(1, 7), isValid: true },
        text: { value: themeColors.text.substring(1, 7), isValid: true },
        button: { value: themeColors.button.substring(1, 7), isValid: true },
        buttonText: { value: themeColors.button_text.substring(1, 7), isValid: true },
        footer: { value: themeColors.footer.substring(1, 7), isValid: true },
        footerText: { value: themeColors.footer_text.substring(1, 7), isValid: true },
    });

    const isFormValid = () => {
        return Object.values(formData).every(field => field.isValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let { background, text, button, buttonText, footer, footerText } = formData;

        // Check if each of the colors starts with a hash otherwise add it
        if (!startsWithHash(background.value)) {
            background.value = `#${background.value}`;
        }

        if (!startsWithHash(text.value)) {
            text.value = `#${text.value}`;
        }

        if (!startsWithHash(button.value)) {
            button.value = `#${button.value}`;
        }

        if (!startsWithHash(buttonText.value)) {
            buttonText.value = `#${buttonText.value}`;
        }

        if (!startsWithHash(footer.value)) {
            footer.value = `#${footer.value}`;
        }

        if (!startsWithHash(footerText.value)) {
            footerText.value = `#${footerText.value}`;
        }

        // Assuming you have a function to call the API to create a new list
        onCreate({ background: background.value, text: text.value, button: button.value, button_text: buttonText.value, footer: footer.value, footer_text: footerText.value });

        setHasChanged(false)

    };

    const handleChange = (e) => {
        let { name, value } = e.target;
        let isValid = false;

        if (value.length > 0) {
            if (/[a-zA-Z]/.test(value.slice(-1)) !== true && /\d/.test(value.slice(-1)) !== true) {
                return;
            }
        }

        if (startsWithHash(value)) {
            value = value.substring(1);
        }

        if (value.length >= 7) {
            value = value.substring(0, 6);
        }

        if (value.length === 6) {
            isValid = true;
        } else {
            isValid = false;
        }

        value = value.toUpperCase()

        setHasChanged(true)

        setFormData({
            ...formData,
            [name]: { value, isValid },
        });
    };

    function startsWithHash(color) {
        return color.startsWith('#');
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <form>
            <div className="max-w-64 mt-3">
                <label htmlFor="background" className="block text-sm font-medium leading-6 text-stone-900">
                    Background
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                    <span
                        className="inline-flex items-center bg-stone-100 rounded-l-md border border-r-0 border-stone-300 px-3 text-stone-500 sm:text-sm">
                      #
                    </span>
                    <input
                        type="text"
                        name="background"
                        id="background"
                        value={formData.background.value}
                        onChange={handleChange}
                        className={classNames(
                            formData.background.isValid ? 'ring-stone-300' : 'ring-red-300',
                            'block w-full min-w-0 flex-1 bg-stone-50 rounded-none rounded-r-md border-0 py-1.5 text-stone-900 ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-1 focus:ring-inset focus:ring-stone-300 sm:text-sm sm:leading-6'
                        )}
                        placeholder="FFF000"
                    />
                </div>
            </div>

            <div className="max-w-64 mt-3">
                <label htmlFor="text" className="block text-sm font-medium leading-6 text-stone-900">
                    Text
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                    <span
                        className="inline-flex items-center bg-stone-100 rounded-l-md border border-r-0 border-stone-300 px-3 text-stone-500 sm:text-sm">
                      #
                    </span>
                    <input
                        type="text"
                        name="text"
                        id="text"
                        value={formData.text.value}
                        onChange={handleChange}
                        className={classNames(
                            formData.text.isValid ? 'ring-stone-300' : 'ring-red-300',
                            'block w-full min-w-0 flex-1 bg-stone-50 rounded-none rounded-r-md border-0 py-1.5 text-stone-900 ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-1 focus:ring-inset focus:ring-stone-300 sm:text-sm sm:leading-6'
                        )}
                        placeholder="FFF000"
                    />
                </div>
            </div>

            <div className="max-w-64 mt-3">
                <label htmlFor="button" className="block text-sm font-medium leading-6 text-stone-900">
                    Button
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                    <span
                        className="inline-flex items-center bg-stone-100 rounded-l-md border border-r-0 border-stone-300 px-3 text-stone-500 sm:text-sm">
                      #
                    </span>
                    <input
                        type="text"
                        name="button"
                        id="button"
                        value={formData.button.value}
                        onChange={handleChange}
                        className={classNames(
                            formData.button.isValid ? 'ring-stone-300' : 'ring-red-300',
                            'block w-full min-w-0 flex-1 bg-stone-50 rounded-none rounded-r-md border-0 py-1.5 text-stone-900 ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-1 focus:ring-inset focus:ring-stone-300 sm:text-sm sm:leading-6'
                        )}
                        placeholder="FFF000"
                    />
                </div>
            </div>

            <div className="max-w-64 mt-3">
                <label htmlFor="buttonText" className="block text-sm font-medium leading-6 text-stone-900">
                    Button text
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                    <span
                        className="inline-flex items-center bg-stone-100 rounded-l-md border border-r-0 border-stone-300 px-3 text-stone-500 sm:text-sm">
                      #
                    </span>
                    <input
                        type="text"
                        name="buttonText"
                        id="buttonText"
                        value={formData.buttonText.value}
                        onChange={handleChange}
                        className={classNames(
                            formData.buttonText.isValid ? 'ring-stone-300' : 'ring-red-300',
                            'block w-full min-w-0 flex-1 bg-stone-50 rounded-none rounded-r-md border-0 py-1.5 text-stone-900 ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-1 focus:ring-inset focus:ring-stone-300 sm:text-sm sm:leading-6'
                        )}
                        placeholder="FFF000"
                    />
                </div>
            </div>

            <div className="max-w-64 mt-3">
                <label htmlFor="footer" className="block text-sm font-medium leading-6 text-stone-900">
                    Footer
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                    <span
                        className="inline-flex items-center bg-stone-100 rounded-l-md border border-r-0 border-stone-300 px-3 text-stone-500 sm:text-sm">
                      #
                    </span>
                    <input
                        type="text"
                        name="footer"
                        id="footer"
                        value={formData.footer.value}
                        onChange={handleChange}
                        className={classNames(
                            formData.footer.isValid ? 'ring-stone-300' : 'ring-red-300',
                            'block w-full min-w-0 flex-1 bg-stone-50 rounded-none rounded-r-md border-0 py-1.5 text-stone-900 ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-1 focus:ring-inset focus:ring-stone-300 sm:text-sm sm:leading-6'
                        )}
                        placeholder="FFF000"
                    />
                </div>
            </div>

            <div className="max-w-64 mt-3">
                <label htmlFor="footerText" className="block text-sm font-medium leading-6 text-stone-900">
                    Footer text
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                    <span
                        className="inline-flex items-center bg-stone-100 rounded-l-md border border-r-0 border-stone-300 px-3 text-stone-500 sm:text-sm">
                      #
                    </span>
                    <input
                        type="text"
                        name="footerText"
                        id="footerText"
                        value={formData.footerText.value}
                        onChange={handleChange}
                        className={classNames(
                            formData.footerText.isValid ? 'ring-stone-300' : 'ring-red-300',
                            'block w-full min-w-0 flex-1 bg-stone-50 rounded-none rounded-r-md border-0 py-1.5 text-stone-900 ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-1 focus:ring-inset focus:ring-stone-300 sm:text-sm sm:leading-6'
                        )}
                        placeholder="FFF000"
                    />
                </div>
            </div>

            <button
                onClick={handleSubmit}
                type="button"
                disabled={!isFormValid()}
                className={classNames(
                    isFormValid() && hasChanged ? 'bg-green-800 hover:bg-stone-600' : 'bg-stone-300',
                    'mt-3 inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-regular text-white'
                )}
            >
                <PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                Save
            </button>
        </form>
    );
};

export default EditTheme;